import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import sendToLogger from "../../Helpers/errorLogger";
import MaskHelper from "../../Helpers/mask";
import { validateCpf } from "../../Helpers/validations";
import userLogo from "../../assets/img/userLogo.png";
import { useAuth } from "../../hooks/useAuth";
import ErrorHandler from "../../services/error-handler";
import NewApi from "../../services/new-api";
import { genderOptions } from "../../services/constants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import InputMask from "react-input-mask";

export default function CompleteUserRegistration() {
  const { isCompletedRegister, isLogged, completeRegister, user } = useAuth();
  const url = window.location.pathname;

  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("CommonExpressions.nameRequired"))
      .matches(/^[^\d]+$/, t("CommonExpressions.nameInvalidCharacters"))
      .min(3, t("CommonExpressions.nameInvalidLength"))
      .trim(),
    lastName: yup
      .string()
      .required(t("CommonExpressions.lastnameRequired"))
      .matches(/^[^\d]+$/, t("CommonExpressions.lastnameInvalidCharacters"))
      .min(3, t("CommonExpressions.lastnameInvalidLength"))
      .trim(),
    document: yup
      .string()
      .required(t("CommonExpressions.cpfRequired"))
      .test(
        "validate-document",
        t("CommonExpressions.cpfInvalid"),
        (document) => validateCpf(document.replace(/[.-]/g, ""))
      ),
    phone: yup.string().required(t("CommonExpressions.phoneRequired")).trim(),
    birthdate: yup
      .string()
      .required(t("CommonExpressions.birthdateRequired"))
      .test(
        "valid-date",
        t("CommonExpressions.birthdateInvalid"),
        (value) =>
          dayjs(value, "DD/MM/YYYY", true).isValid() &&
          dayjs(value, "DD/MM/YYYY").isBefore(dayjs(), "year")
      ),
    gender: yup.string().required(t("CommonExpressions.genderRequired")),
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user.first_name,
      lastName: user.last_name,
      document: user.document,
      gender: user.gender,
      birthdate: user.birthdate
        ? dayjs(user.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY")
        : "",
    },
  });

  const onSubmit = (data) => {
    setSubmitting(true);

    const formData = {
      first_name: data.firstName,
      last_name: data.lastName,
      document: MaskHelper.alphanumericMask(data.document),
      gender: data.gender,
      birthdate: dayjs(data.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };

    new NewApi()
      .put("users/update", formData)
      .then(async () => {
        await completeRegister();
      })
      .catch((error) => {
        sendToLogger(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: ErrorHandler.handleFormErrors(error.response.data, t),
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal
      show={isLogged && !isCompletedRegister && url.includes("checkout")}
      backdrop="static"
      id="login-modal"
    >
      <Modal.Header className="new-modal-header">
        <img alt="" src={userLogo} />
        <Modal.Title className="new-modal-title text-center">
          {t("Complete.completeRegistration")}
        </Modal.Title>
        <p className="modal-p">{t("Complete.completePurchase")}</p>
      </Modal.Header>
      <Modal.Body className="new-modal-body">
        <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>{t("CommonExpressions.name")}</Form.Label>
                <Form.Control type="text" {...register("firstName")} />
                {errors.firstName && (
                  <Form.Text className="text-danger">
                    {errors.firstName.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>{t("CommonExpressions.lastname")}</Form.Label>
                <Form.Control type="text" {...register("lastName")} />
                {errors.lastName && (
                  <Form.Text className="text-danger">
                    {errors.lastName.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="document">
                <Form.Label>{t("CommonExpressions.CPF")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("document")}
                  onChange={(e) =>
                    setValue("document", MaskHelper.cpfMask(e.target.value))
                  }
                />
                {errors.document && (
                  <Form.Text className="text-danger">
                    {errors.document.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="phone">
                <Form.Label>{t("CommonExpressions.phone")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("phone")}
                  as={InputMask}
                  mask="(99) 99999-9999"
                />
                {errors.phone && (
                  <Form.Text className="text-danger">
                    {errors.phone.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="birthdate">
                <Form.Label>{t("CommonExpressions.birthdate")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("birthdate")}
                  onChange={(e) =>
                    setValue("birthdate", MaskHelper.dateMask(e.target.value))
                  }
                />
                {errors.birthdate && (
                  <Form.Text className="text-danger">
                    {errors.birthdate.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="gender">
                <Form.Label>
                  {t("CommonExpressions.whatIsYourGender")}
                </Form.Label>
                <Form.Control
                  as="select"
                  {...register("gender")}
                  onChange={(e) => setValue("gender", e.target.value)}
                >
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {t(`CommonExpressions.${option.label}`)}
                    </option>
                  ))}
                </Form.Control>
                {errors.gender && (
                  <Form.Text className="text-danger">
                    {errors.gender.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                className="btn-logar mt-3"
                disabled={submitting}
              >
                {submitting
                  ? t("CommonExpressions.updating")
                  : t("Complete.completeInformation")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
