import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

import "./index.css";

import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import * as yup from "yup";
import sendToLogger from "../../Helpers/errorLogger";
import MaskHelper from "../../Helpers/mask";
import { validateCpf } from "../../Helpers/validations";
import checked from "../../assets/img/checked.png";
import userLogo from "../../assets/img/userLogo.png";
import ErrorHandler from "../../services/error-handler";
import KondutoService from "../../services/konduto";
import NewApi from "../../services/new-api";
import { genderOptions } from "../../services/constants";
import { pushGAEvent } from "../../Helpers/tagManager";
import useWindowSize from "../../hooks/useWindowSize";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const api = new NewApi();
const invalidCharacters = /^[^\d=@]+$/;
const accentuationAndHifen =
  /^(?:[a-zA-Z\u00C0-\u017F]+(?:[-\s](?:[a-zA-Z\u00C0-\u017F]+))*)$/;
const spaces = /^[^\s]*\s?[^\s]*$/;

const nomeEmpresa = process.env.REACT_APP_NAME_EMPRESA;
const sitekey = process.env.REACT_APP_RECAPTCHA_KEY;

export default function Index({ show, closeRegister }) {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("ProfileForm.emailRequired"))
      .email(t("ProfileForm.invalidEmail")),
    firstName: yup
      .string()
      .required(t("CommonExpressions.nameRequired"))
      .matches(invalidCharacters, t("CommonExpressions.nameInvalidCharacters"))
      .matches(accentuationAndHifen, t("CommonExpressions.typedCorrectly"))
      .min(3, t("CommonExpressions.nameInvalidLength"))
      .matches(spaces, t("Register.spaceBetweenNames"))
      .trim(),
    lastName: yup
      .string()
      .required(t("CommonExpressions.lastnameRequired"))
      .matches(
        invalidCharacters,
        t("CommonExpressions.lastnameInvalidCharacters")
      )
      .matches(accentuationAndHifen, t("CommonExpressions.typedCorrectly"))
      .min(3, t("CommonExpressions.lastnameInvalidLength"))
      .matches(spaces, t("Register.spaceBetweenNames"))
      .trim(),
    document: yup
      .string()
      .required(t("CommonExpressions.cpfRequired"))
      .test(
        "validate-document",
        t("CommonExpressions.cpfInvalid"),
        (document) => validateCpf(document.replace(/[.-]/g, ""))
      ),
    phone: yup.string().required(t("CommonExpressions.phoneRequired")).trim(),
    birthdate: yup
      .string()
      .required(t("CommonExpressions.birthdateRequired"))
      .test(
        "valid-date",
        t("CommonExpressions.birthdateInvalid"),
        (value) =>
          dayjs(value, "DD/MM/YYYY", true).isValid() &&
          dayjs(value, "DD/MM/YYYY").isBefore(dayjs(), "year")
      ),
    gender: yup.string().required(t("CommonExpressions.genderRequired")),
    password: yup
      .string()
      .required(t("Register.passwordNotProvided"))
      .min(6, t("Register.passwordLength")),
    confirmPassword: yup
      .string()
      .required(t("Register.passwordConfirmationNotProvided"))
      .equals([yup.ref("password")], t("Register.passwordsNotMatch")),
  });

  const recaptchaRef = useRef();
  const isMobile = useWindowSize();

  const [loading, setLoading] = useState(false);

  const [divError, setDivError] = useState(false);
  const [divSuccess, setDivSuccess] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [header, setHeader] = useState(t("Register.createAccount"));
  const [headerSub, setHeaderSub] = useState(t("Register.enterYourData"));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  function userRegister(token, data) {
    api
      .post("users", {
        token,
        ...data,
      })
      .then(async ({ data }) => {
        await MoengageService.createEvent({
          type: "event",
          customer_id: data.id,
          actions: [
            {
              action: "sign_up",
              attributes: {
                ...data,
              },
              platform: "web",
            },
          ],
        });

        await MoengageService.trackUser({
          type: "customer",
          customer_id: data.id,
          attributes: {
            ...data,
            platforms: [{ platform: "web", active: true }],
          },
        });

        pushGAEvent("sign_up", { method: "Account" });

        setDivSuccess(true);
        setDivError(false);
        setHeader(t("Register.congratulations"));
        setHeaderSub(t("Register.nowLoginAndBuy"));
      })
      .catch((error) => {
        recaptchaRef.current.reset();

        setDivError(true);
        setDivSuccess(false);
        sendToLogger(error);
        setMsgError(ErrorHandler.handleFormErrors(error.response.data, t));
      })
      .finally(() => setLoading(false));
  }

  const onSubmit = async (data) => {
    const params = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      document: MaskHelper.alphanumericMask(data.document),
      birthdate: dayjs(data.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      gender: data.gender,
      password: data.password,
      password_confirmation: data.confirmPassword,
      telephone:  MaskHelper.numberMask(data.phone),
    };

    setLoading(true);

    try {
      const captchaToken = await recaptchaRef.current.executeAsync();
      userRegister(captchaToken, params);
    } catch (error) {
      setLoading(false);
      sendToLogger(error);
      Swal.fire({
        icon: "warning",
        title: t("Register.invalidCaptchaTitle"),
        text: t("Register.invalidCaptchaMessage"),
      });
    }
  };

  useEffect(() => {
    if (show && recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.reset();
      KondutoService.sendEvent("page", "account_creation");
    }
  }, [recaptchaRef, show]);

  return (
    <>
      <Modal
        show={show}
        onHide={closeRegister}
        size="md"
        scrollable={isMobile}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="new-modal-header">
          <img alt="" src={userLogo} />
          <Modal.Title className="new-modal-title">{header}</Modal.Title>
          <p className="modal-p text-center">{headerSub}</p>
        </Modal.Header>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close-icon-login"
          onClick={closeRegister}
        />
        <Modal.Body className="new-modal-body">
          {loading ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ minHeight: 200 }}
            >
              <Spinner animation="border" variant="primary" />
              <h5>{t("Register.processing")}</h5>
            </div>
          ) : (
            <>
              <div className={divError ? "alert alert-danger" : "hidden"}>
                <span dangerouslySetInnerHTML={{ __html: msgError }}></span>
              </div>

              {divSuccess ? (
                <div className="text-center">
                  <img
                    alt=""
                    src={checked}
                    className="checked-register text-center"
                  />

                  <button
                    onClick={closeRegister}
                    className="login-register-bnt mb-4 mt-4"
                  >
                    {t("Register.login")}
                  </button>
                </div>
              ) : (
                <>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col>
                        <Form.Group controlId="email">
                          <Form.Label>{t("Checkout.email")}</Form.Label>
                          <Form.Control type="email" {...register("email")} />
                          {errors.email && (
                            <Form.Text className="text-danger">
                              {errors.email.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={6} md={6} lg={6}>
                        <Form.Group controlId="firstName">
                          <Form.Label>{t("CommonExpressions.name")}</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("firstName")}
                          />
                          {errors.firstName && (
                            <Form.Text className="text-danger">
                              {errors.firstName.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xl={6} md={6} lg={6}>
                        <Form.Group controlId="lastName">
                          <Form.Label>
                            {t("CommonExpressions.lastname")}
                          </Form.Label>
                          <Form.Control type="text" {...register("lastName")} />
                          {errors.lastName && (
                            <Form.Text className="text-danger">
                              {errors.lastName.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="document">
                          <Form.Label>{t("CommonExpressions.CPF")}</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("document")}
                            as={InputMask}
                            mask="999.999.999-99"
                          />
                          {errors.document && (
                            <Form.Text className="text-danger">
                              {errors.document.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group controlId="phone">
                          <Form.Label>{t("CommonExpressions.phone")}</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("phone")}
                            as={InputMask}
                            mask="(99) 99999-9999"
                          />
                          {errors.phone && (
                            <Form.Text className="text-danger">
                              {errors.phone.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="birthdate">
                          <Form.Label>
                            {t("CommonExpressions.birthdate")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            {...register("birthdate")}
                            onChange={(e) =>
                              setValue(
                                "birthdate",
                                MaskHelper.dateMask(e.target.value)
                              )
                            }
                          />
                          {errors.birthdate && (
                            <Form.Text className="text-danger">
                              {errors.birthdate.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="gender">
                          <Form.Label>
                            {t("CommonExpressions.whatIsYourGender")}
                          </Form.Label>
                          <Form.Control as="select" {...register("gender")}>
                            {genderOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {t(`CommonExpressions.${option.label}`)}
                              </option>
                            ))}
                          </Form.Control>
                          {errors.gender && (
                            <Form.Text className="text-danger">
                              {errors.gender.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={6} md={6} lg={6}>
                        <Form.Group controlId="password">
                          <Form.Label>
                            {t("CommonExpressions.password")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            {...register("password")}
                          />
                          {errors.password && (
                            <Form.Text className="text-danger">
                              {errors.password.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xl={6} md={6} lg={6}>
                        <Form.Group controlId="confirmPassowrd">
                          <Form.Label>
                            {t("Register.confirmPassword")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            {...register("confirmPassword")}
                          />
                          {errors.confirmPassword && (
                            <Form.Text className="text-danger">
                              {errors.confirmPassword.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button type="submit" className="btn-logar">
                      {t("Register.signup")}
                    </Button>
                  </Form>
                </>
              )}
            </>
          )}
          <ReCAPTCHA ref={recaptchaRef} sitekey={sitekey} size="invisible" />
        </Modal.Body>
        <Modal.Footer className="new-modal-footer" style={{ marginTop: 0 }}>
          <div className="w-100">
            <p className="text-center p-register">
              {t("Register.termsOfUseAcceptance")} <br />
              <a href="/termos" target="_blank">
                {t("Footer.termsOfUse")}
              </a>{" "}
              {t("CommonExpressions.of")} {nomeEmpresa}
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
